var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-modalidade new-tabs" },
    [
      _c(
        "vertical-tabs-components",
        { attrs: { tabs: _vm.tabs } },
        [
          _c("configuracao-component", {
            attrs: {
              slot: "tab-content-" + _vm.tabs[0].tab,
              condicoesEspeciais: _vm.modalidadeCondicoes,
              objeto: _vm.modalidadeObjeto,
              CondicoesGerais: _vm.CondicoesGerais,
              detalhes: _vm.modalidade,
              isComplementaryModality: false
            },
            on: {
              modalitySaved: function($event) {
                return _vm.getModalidade()
              }
            },
            slot: "tab-content-" + _vm.tabs[0].tab
          }),
          _c("coberturas-adicionais", {
            attrs: {
              slot: "tab-content-" + _vm.tabs[1].tab,
              coverage: _vm.coberturasAdicionais
            },
            slot: "tab-content-" + _vm.tabs[1].tab
          }),
          _c("modalidades-complementares", {
            attrs: {
              slot: "tab-content-" + _vm.tabs[2].tab,
              mainModalityId: _vm.modalidade.Id,
              modalities: _vm.complementaryModalities
            },
            slot: "tab-content-" + _vm.tabs[2].tab
          }),
          _c("historicoAlteracoes", {
            attrs: {
              slot: "tab-content-" + _vm.tabs[3].tab,
              id: _vm.modalidade.Id
            },
            slot: "tab-content-" + _vm.tabs[3].tab
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }