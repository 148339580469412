<template>
  <section class="coverage">
    <vs-row vs-type="flex" class="justify-between pt-0">
      <h4 class="font-semibold">
        Modalidades Complementares
      </h4>

      <vs-button @click="openModalComplementary(clearModality)">
        Adicionar Modalidade Complementar
      </vs-button>
    </vs-row>
    <div class="header">
      <div class="pt-4 mt-5 flex flex-row-reverse w-full"></div>
    </div>

    <div class="coverage-list">
      <template v-if="modalitiesArr.length > 0">
        <vs-table :data="modalitiesArr">
          <template slot="header">
            <h4 class="ml-2 mb-2"></h4>
          </template>

          <template slot="thead">
            <vs-th>
              Modalidade
            </vs-th>

            <vs-th>
              Data de cadastro
            </vs-th>

            <!-- <vs-th>
              Código de referência
            </vs-th> -->

            <vs-th>
              Status
            </vs-th>

            <vs-th>
              Ações
            </vs-th>
          </template>

          <template slot-scope="{ data }" v-if="data.length">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].Name">
                {{ data[indextr].Name }}
              </vs-td>

              <vs-td :data="data[indextr].CreateDate">
                {{ data[indextr].CreateDate | moment("DD/MM/YYYY") }}
              </vs-td>

              <vs-td :data="data[indextr].Ref">
                {{ data[indextr].Ref }}
              </vs-td>

              <vs-td>
                {{ data[indextr].IsDeleted ? "Inativa" : "Ativa" }}
              </vs-td>
              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    style="width: 200px; z-index: 53001 !important;"
                  >
                    <vs-dropdown-item
                      @click.native="openModalComplementary(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Editar</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click.native="
                        openModalDelete(
                          data[indextr].Name,
                          data[indextr].Id,
                          data[indextr].IsDeleted
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Inativar/Ativar</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </div>

    <vs-popup
      title="Modalidade complementar"
      class="popup-complementary-modality"
      :active.sync="modalComplementary"
    >
      <configuracao-component
        :condicoesEspeciais="complementaryModalityCondition"
        :objeto="complementaryModalityObject"
        :CondicoesGerais="CondicoesGerais"
        :detalhes="complementaryModalityObj"
        :isComplementaryModality="true"
        :mainModalityId="mainModalityId"
        @modalitySaved="onComplementaryModalitySaved"
        @atualizar-Detalhes="atualizarDetalhes"
      />
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title=""
      :active.sync="modalDelete"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full flex justify-center mb-10">
          <h4 class="text-center">
            Deseja realmente {{ modalityIsDeleted ? "ativar" : "inativar" }} a
            modalidade complementar <b>{{ complementaryModalityName }}</b
            >?
          </h4>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="cancelDelete()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button
            @click="confirmDelete(complementaryModalityId)"
            class="button-primary"
          >
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import ConfiguracaoComponent from "./ConfiguracaoComponent";

export default {
  components: { VueEditor, ConfiguracaoComponent },
  props: {
    modalities: Array,
    mainModalityId: Number
  },
  data() {
    return {
      clearModality: {
        IsComplementaryModality: true,
        DaysForAutomaticCancel: 0
      },
      modalComplementary: false,
      complementaryModalityObj: {},
      modalityContractualTermTypes: [1, 2, 3],
      CondicoesGerais: { Value: "" , JSONTag:"", Tags: []  },
      complementaryModalityObject: { Value: "" , JSONTag:"", Tags: []  },
      complementaryModalityCondition: { Value: "" , JSONTag:"", Tags: []  },
      modalDelete: false,
      complementaryModalityId: null,
      complementaryModalityName: null,
      modalitiesArr: this.modalities || [],
      modalityIsDeleted: null
    };
  },
  mounted() {
    // this.getCoverages();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getModalityObject",
      "saveModalityCoverage",
      "UpdateCoverage",
      "enableDisableComplementaryModality",
      "getComplementaryModality",
      "updateModalityCoverage"
    ]),
    openModalDelete(
      complementaryModalityName,
      complementaryModalityId,
      isDeleted
    ) {
      this.modalDelete = true;
      this.complementaryModalityName = complementaryModalityName;
      this.complementaryModalityId = complementaryModalityId;
      this.modalityIsDeleted = isDeleted;
    },
    cancelDelete() {
      this.modalDelete = false;
      this.complementaryModalityName = "";
      this.complementaryModalityId = null;
      this.modalityIsDeleted = null;
    },
    confirmDelete(complementaryModalityId) {
      this.deleteComplementaryModality(complementaryModalityId);
    },
    async openModalComplementary(originalComplementaryModalityObject) {
      this.complementaryModalityObj = this.$utils.deepClone(
        originalComplementaryModalityObject
      );
      // this.$forceUpdate();
      if (this.complementaryModalityObj.Id) {
        this.complementaryModalityCondition = { Value: "" , JSONTag:"", Tags: []  };
        this.complementaryModalityObject = { Value: "" , JSONTag:"", Tags: []  };
        this.CondicoesGerais = { Value: "" , JSONTag:"", Tags: []  };
        await this.getModalityObjects(this.complementaryModalityObj.Id);
      }
      this.modalComplementary = true;
    },
    saveCoverage(coverage) {},

    atualizarDetalhes(complementaryModalityObj) {
      this.complementaryModalityObj = complementaryModalityObj;
      this.$forceUpdate();
    },
    async getModalityObjects(complementaryModalityId) {
      await this.modalityContractualTermTypes.forEach(async type => {
        await this.$onpoint.loading(async() => {
          return await this.getModalityObject({
            modalityId: complementaryModalityId,
            termType: type
          }).then(resp => {
            if (resp) {
              if (type === 1) {
                this.CondicoesGerais = resp;
              }
              if (type === 3) {
                this.complementaryModalityObject = resp;
              }
               if (type === 2) {
                this.complementaryModalityCondition = resp;
              }
            }
          });
        });
      });
    },
    async deleteComplementaryModality(complementaryModalityId) {
      await this.$onpoint.loading(async() => {
        return await this.enableDisableComplementaryModality(complementaryModalityId)
          .then(resp => {
            this.updateComplementaryModalitiesList();
            this.$onpoint.successModal(
              `Modalidade complementar ${
                this.modalityIsDeleted ? "inativada" : "ativada"
              } com sucesso.`
            );
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          })
          .finally(() => {
            this.modalDelete = false;
            this.complementaryModalityName = "";
            this.complementaryModalityId = null;
            this.modalityIsDeleted = null;
            this.$forceUpdate();
          });
      });
    },
    async updateComplementaryModalitiesList() {
      await this.$onpoint.loading(async () => {
        return await this.getComplementaryModality(
          this.$route.params.modalidadeUniqueId
        ).then(response => {
          if (response.length) {
            this.modalitiesArr = response;
          } else {
            this.modalitiesArr = [];
            // return;
          }
        });
      });
    },
    onComplementaryModalitySaved(event) {
      this.updateComplementaryModalitiesList();
      this.modalComplementary = false;
      // this.getModalityObjects(event);
    }
  }
};
</script>

<style lang="scss">
.success-modal,
.error-modal {
  z-index: 60000;
}
.popup-complementary-modality {
  .vs-popup {
    width: 1200px !important;
    height: 90%;

    .vs-popup--content {
      overflow: auto;
    }
  }
}
#objeto,
#condicoes {
  height: 450px;
}
</style>

<style lang="scss" scoped>
.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>