import { render, staticRenderFns } from "./CoberturasAdicionais.vue?vue&type=template&id=5ac19fca&scoped=true&"
import script from "./CoberturasAdicionais.vue?vue&type=script&lang=js&"
export * from "./CoberturasAdicionais.vue?vue&type=script&lang=js&"
import style0 from "./CoberturasAdicionais.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CoberturasAdicionais.vue?vue&type=style&index=1&id=5ac19fca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac19fca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ac19fca')) {
      api.createRecord('5ac19fca', component.options)
    } else {
      api.reload('5ac19fca', component.options)
    }
    module.hot.accept("./CoberturasAdicionais.vue?vue&type=template&id=5ac19fca&scoped=true&", function () {
      api.rerender('5ac19fca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/parametrizacao/components/modalidade/CoberturasAdicionais.vue"
export default component.exports