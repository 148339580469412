var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "coverage" },
    [
      _c(
        "vs-row",
        { staticClass: "justify-between pt-0", attrs: { "vs-type": "flex" } },
        [
          _c("h4", { staticClass: "font-semibold" }, [
            _vm._v("\n      Modalidades Complementares\n    ")
          ]),
          _c(
            "vs-button",
            {
              on: {
                click: function($event) {
                  return _vm.openModalComplementary(_vm.clearModality)
                }
              }
            },
            [_vm._v("\n      Adicionar Modalidade Complementar\n    ")]
          )
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "coverage-list" },
        [
          _vm.modalitiesArr.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.modalitiesArr },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return data.length
                              ? _vm._l(data, function(tr, indextr) {
                                  return _c(
                                    "vs-tr",
                                    { key: indextr },
                                    [
                                      _c(
                                        "vs-td",
                                        { attrs: { data: data[indextr].Name } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(data[indextr].Name) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "vs-td",
                                        {
                                          attrs: {
                                            data: data[indextr].CreateDate
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  data[indextr].CreateDate,
                                                  "DD/MM/YYYY"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "vs-td",
                                        { attrs: { data: data[indextr].Ref } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(data[indextr].Ref) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c("vs-td", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              data[indextr].IsDeleted
                                                ? "Inativa"
                                                : "Ativa"
                                            ) +
                                            "\n            "
                                        )
                                      ]),
                                      _c(
                                        "vs-td",
                                        [
                                          _c(
                                            "vs-dropdown",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                "vs-custom-content": "",
                                                "vs-trigger-click": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "vs-button",
                                                {
                                                  attrs: {
                                                    color: "dark",
                                                    type: "flat",
                                                    title: "Ações"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                    attrs: { role: "button" }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "vs-dropdown-menu",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                    "z-index":
                                                      "53001 !important"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openModalComplementary(
                                                            data[indextr]
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Editar")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openModalDelete(
                                                            data[indextr].Name,
                                                            data[indextr].Id,
                                                            data[indextr]
                                                              .IsDeleted
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Inativar/Ativar"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              : undefined
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h4", { staticClass: "ml-2 mb-2" })
                    ]),
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v("\n            Modalidade\n          ")
                        ]),
                        _c("vs-th", [
                          _vm._v("\n            Data de cadastro\n          ")
                        ]),
                        _c("vs-th", [
                          _vm._v("\n            Status\n          ")
                        ]),
                        _c("vs-th", [_vm._v("\n            Ações\n          ")])
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-complementary-modality",
          attrs: {
            title: "Modalidade complementar",
            active: _vm.modalComplementary
          },
          on: {
            "update:active": function($event) {
              _vm.modalComplementary = $event
            }
          }
        },
        [
          _c("configuracao-component", {
            attrs: {
              condicoesEspeciais: _vm.complementaryModalityCondition,
              objeto: _vm.complementaryModalityObject,
              CondicoesGerais: _vm.CondicoesGerais,
              detalhes: _vm.complementaryModalityObj,
              isComplementaryModality: true,
              mainModalityId: _vm.mainModalityId
            },
            on: {
              modalitySaved: _vm.onComplementaryModalitySaved,
              "atualizar-Detalhes": _vm.atualizarDetalhes
            }
          })
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "", active: _vm.modalDelete },
          on: {
            "update:active": function($event) {
              _vm.modalDelete = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full flex justify-center mb-10" },
              [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Deseja realmente " +
                      _vm._s(_vm.modalityIsDeleted ? "ativar" : "inativar") +
                      " a\n          modalidade complementar "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.complementaryModalityName))]),
                  _vm._v("?\n        ")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        return _vm.cancelDelete()
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.confirmDelete(_vm.complementaryModalityId)
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }