<template>
  <div class="config-modalidade new-tabs">
    <vertical-tabs-components :tabs="tabs">
      <configuracao-component
        :condicoesEspeciais="modalidadeCondicoes"
        :objeto="modalidadeObjeto"
        :CondicoesGerais="CondicoesGerais"
        :detalhes="modalidade"
        :isComplementaryModality="false"
        @modalitySaved="getModalidade()"
        :slot="`tab-content-${tabs[0].tab}`"
      />

      <coberturas-adicionais
        :slot="`tab-content-${tabs[1].tab}`"
        :coverage="coberturasAdicionais"
      />

      <modalidades-complementares
        :slot="`tab-content-${tabs[2].tab}`"
        :mainModalityId="modalidade.Id"
        :modalities="complementaryModalities"
      />

      <historicoAlteracoes
        :slot="`tab-content-${tabs[3].tab}`"
        :id="modalidade.Id"
      />
    </vertical-tabs-components>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import ConfiguracaoComponent from "./components/modalidade/ConfiguracaoComponent";
import CoberturasAdicionais from "./components/modalidade/CoberturasAdicionais";
import ModalidadesComplementares from "./components/modalidade/ModalidadesComplementares";
import historicoAlteracoes from "./components/modalidade/historicoAlteracoes";
import VerticalTabsComponents from "@/components/vertical-tabs/VerticalTabsComponents.vue";

export default {
  components: {
    Breadcrumb,
    ConfiguracaoComponent,
    CoberturasAdicionais,
    ModalidadesComplementares,
    VerticalTabsComponents,
    historicoAlteracoes
  },
  data() {
    return {
      modalidadeTypes: [1, 2, 3],
      coberturaTypes: [4, 5],
      coberturasAdicionais: [],
      modalidade: {},
      HasComplementaryModality: false,
      modalidadeObjeto: {},
      modalidadeCondicoes: {},
      CondicoesGerais: {},
      coberturaObjeto: {},
      coberturaCondicoes: {},
      complementaryModalities: []
    };
  },
  computed: {
    tabs() {
      return [
        { label: "Modalidade", tab: "modalidade", disabled: false },
        {
          label: "Coberturas Adicionais",
          tab: "coberturas-adicionais",
          disabled: false
        },
        {
          label: "Modalidades Complementares",
          tab: "modalidade-complementar",
          disabled: !this.HasComplementaryModality
        },
        {
          label: "Histórico de Alterações",
          tab: "historico-alteracoes",
          disabled: false
        }
      ];
    }
  },
  mounted() {
    if (this.$route.params.modalidadeId) {
      this.getModalidade();
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "param-configurar-modalidade",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getModality",
      "getModalityObject",
      "getAdditionalCoverages",
      "getComplementaryModality"
    ]),

    async getObjeto(type, Id) {
      return await this.getModalityObject({
        modalityId: Id,
        termType: type
      }).then(response => {
        switch (type) {
          case 1:
            if (!response) {
              this.CondicoesGerais = { Value: "", JSONTag: "", Tags: [] };
            } else {
              this.CondicoesGerais = response;
            }
            break;
          case 2:
            if (!response) {
              this.modalidadeCondicoes = { Value: "", JSONTag: "", Tags: [] };
            } else {
              this.modalidadeCondicoes = response;
            }
            break;
          case 3:
            if (!response) {
              this.modalidadeObjeto = { Value: "", JSONTag: "", Tags: [] };
            } else {
              this.modalidadeObjeto = response;
            }

            break;
          case 4:
            this.coberturaObjeto = response;
            break;
          case 5:
            this.coberturaCondicoes = response;
            break;
        }
        return response;
      });
    },

    async getModalidade() {
     await this.$onpoint.loading(async () => {
        return await this.getModality(this.$route.params.modalidadeId)
          .then(async response => {
            this.modalidade = response || {};
            this.HasComplementaryModality = this.modalidade.HasComplementaryModality;
            this.getModalidadeType();
            this.getCoberturasAdicionais();
            if (response.HasComplementaryModality) {
              await this.getComplementaryModality(
                this.$route.params.modalidadeUniqueId
              ).then(response => {
                if (response.length) {
                  this.complementaryModalities = response;
                } else {
                  this.complementaryModalities = [];
                  // return;
                }
              });
            }
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    },

    getModalidadeType() {
      this.modalidadeTypes.forEach(type => {
        this.$onpoint.loading(() => {
          return this.getObjeto(type, this.$route.params.modalidadeId);
        });
      });
    },

    async getCoberturasAdicionais() {
      await this.$onpoint.loading(async () => {
        return await this.getAdditionalCoverages({
          uniqueId: this.$route.params.modalidadeUniqueId,
          ignoreDeleted: false
        })
          .then(response => {
            this.coberturasAdicionais = response;
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    }
  }
};
</script>
