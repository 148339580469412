var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "documents" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" },
          [
            _c(
              "vs-button",
              {
                on: {
                  click: function($event) {
                    return _vm.openModalDocumentos(_vm.documentDefault)
                  }
                }
              },
              [_vm._v("Adicionar Documento")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "document-list" },
        [
          _vm.documentsArr
            ? [
                _c(
                  "vs-table",
                  {
                    attrs: {
                      data: _vm.documentsArr,
                      noDataText: "Nenhum documento cadastrado"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(item, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: item } },
                                [
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.Name) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.getEsteiras(item.PipeIdList || [])
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            item.CreateDate,
                                            "DD/MM/YYYY"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _c("vs-td", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          !item.IsDeleted ? "Ativo" : "Inativo"
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticStyle: {
                                                width: "200px",
                                                "z-index": "53001 !important"
                                              }
                                            },
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.openModalDocumentos(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Editar")
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.openModalDelete(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.IsDeleted
                                                                ? "Ativar"
                                                                : "Inativar"
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      2359469694
                    )
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h4", { staticClass: "ml-2 mb-2" }, [
                        _vm._v("\n            Documentos\n          ")
                      ])
                    ]),
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", { staticClass: "blue-header" }, [
                          _vm._v("\n            Nome\n          ")
                        ]),
                        _c("vs-th", { staticClass: "blue-header" }, [
                          _vm._v("\n            Esteiras\n          ")
                        ]),
                        _c("vs-th", { staticClass: "blue-header" }, [
                          _vm._v("\n            Data de cadastro\n          ")
                        ]),
                        _c("vs-th", { staticClass: "blue-header" }, [
                          _vm._v("\n            Status\n          ")
                        ]),
                        _c("vs-th", { staticClass: "blue-header" }, [
                          _vm._v("\n            Ações\n          ")
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-document",
          attrs: { title: "Documento", active: _vm.modalDocumentos },
          on: {
            "update:active": function($event) {
              _vm.modalDocumentos = $event
            }
          }
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "div",
                      { staticClass: "vx-row p-3" },
                      [
                        _c("ValidationProvider", {
                          staticClass: "w-full p-2",
                          attrs: {
                            name: "nome",
                            rules: "required",
                            tag: "div"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("vs-input", {
                                      staticClass: "w-full ",
                                      attrs: {
                                        label: "Nome",
                                        size: "large",
                                        danger: !!errors[0],
                                        "danger-text": errors[0]
                                      },
                                      model: {
                                        value: _vm.documentObj.Name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.documentObj, "Name", $$v)
                                        },
                                        expression: "documentObj.Name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("ValidationProvider", {
                          staticClass: "w-full p-2",
                          attrs: {
                            name: "esteiras",
                            rules: "required",
                            tag: "div"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("p", [_vm._v("Esteiras")]),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Name",
                                        size: "large",
                                        placeholder:
                                          "Selecione uma ou mais esteiras",
                                        multiple: "",
                                        options: _vm.pipesArr,
                                        reduce: function(pipes) {
                                          return pipes.Id
                                        },
                                        dir: _vm.$vs.rtl ? "rtl" : "ltr"
                                      },
                                      model: {
                                        value: _vm.documentObj.PipeIdList,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.documentObj,
                                            "PipeIdList",
                                            $$v
                                          )
                                        },
                                        expression: "documentObj.PipeIdList"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: errors[0],
                                            expression: "errors[0]"
                                          }
                                        ],
                                        staticClass: "text-danger text-sm"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(errors[0]) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-row p-5" },
                      [
                        _c("vs-switch", {
                          model: {
                            value: _vm.documentObj.IsRequired,
                            callback: function($$v) {
                              _vm.$set(_vm.documentObj, "IsRequired", $$v)
                            },
                            expression: "documentObj.IsRequired"
                          }
                        }),
                        _c("label", { staticClass: "ml-2" }, [
                          _vm._v(" Obrigatório")
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-row p-3" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse w-full mr-3" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { disabled: invalid },
                              on: {
                                click: function($event) {
                                  return _vm.saveDocument(_vm.documentObj)
                                }
                              }
                            },
                            [_vm._v("Salvar")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: "", active: _vm.modalDelete },
          on: {
            "update:active": function($event) {
              _vm.modalDelete = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full flex justify-center mb-10" },
              [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          Deseja realmente\n          " +
                      _vm._s(_vm.dataStatus.isDeleted ? "inativar" : "ativar") +
                      " o documento\n          "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.dataStatus.name))]),
                  _vm._v("?\n        ")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        return _vm.cancelDelete()
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.confirmDelete()
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }