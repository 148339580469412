var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "contractual-term-container" } },
    [
      _vm._l(_vm.jsonTags, function(item, index) {
        return _c("div", { key: index, staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-4/12 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { size: "large", label: "Label", value: item.Label },
                on: {
                  input: function($event) {
                    return _vm.setInputTag({ Label: $event }, item)
                  },
                  change: function($event) {
                    return _vm.setName(item, $event.target.value)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-3/12 w-full pb-5" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { size: "large", label: "Tag", value: item.Name },
                on: {
                  input: function($event) {
                    return _vm.setInputTag({ Name: $event }, item)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-3/12 w-full pb-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Selecione o tipo")
              ]),
              _c("v-select", {
                staticClass: "w-full",
                attrs: {
                  label: "name",
                  size: "large",
                  placeholder: "Tipo",
                  options: _vm.options,
                  value: item.Type || "Text",
                  reduce: function(option) {
                    return option.value
                  },
                  dir: _vm.$vs.rtl ? "rtl" : "ltr"
                },
                on: {
                  input: function($event) {
                    return _vm.setInputTag({ Type: $event }, item)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-2/12 w-full m-auto" },
            [
              _c(
                "vs-row",
                [
                  _c(
                    "vs-tooltip",
                    { attrs: { text: "Campos do Repeater" } },
                    [
                      item.Type == "Repeater"
                        ? _c("vs-button", {
                            staticClass: "vs-col md:w-1/12 w-full m-auto",
                            attrs: {
                              type: "flat",
                              icon: "view_agenda",
                              size: "large"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.openRepeaterFields(item)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("vs-button", {
                    staticClass: "vs-col md:w-1/12 w-full m-auto",
                    attrs: { type: "flat", icon: "close", size: "large" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.removeTag(item)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      }),
      _c(
        "div",
        { staticClass: "p-4 mt-5 flex flex-row-reverse w-full" },
        [
          _c(
            "vs-button",
            {
              staticClass: "px-1",
              attrs: { type: "flat", icon: "add_circle", "icon-after": true },
              on: {
                click: function($event) {
                  return _vm.addTag()
                }
              }
            },
            [_vm._v("\n      Incluir TAG")]
          )
        ],
        1
      ),
      _c(
        "vs-row",
        { staticClass: "mb-5" },
        [
          _c("vs-col", { attrs: { "vs-w": "12" } }, [
            _c("h4", [_vm._v("TAGs de preenchimento automático")])
          ]),
          _vm._l(_vm.tagsAutomatic, function(item, index) {
            return _c(
              "vs-col",
              {
                key: "tag-name-" + index,
                staticClass: "mt-3",
                attrs: { "vs-lg": "6", "vs-sm": "12" }
              },
              [
                _c(
                  "label",
                  { staticClass: "count-container" },
                  [
                    _c("vs-input-number", {
                      staticClass: "pr-3",
                      on: {
                        input: function($event) {
                          return _vm.setAutoTag(item)
                        }
                      },
                      model: {
                        value: _vm.autoTagCoutList[item.Name],
                        callback: function($$v) {
                          _vm.$set(_vm.autoTagCoutList, item.Name, _vm._n($$v))
                        },
                        expression: "autoTagCoutList[item.Name]"
                      }
                    }),
                    _vm._v("\n        " + _vm._s(item["Label"]) + "\n      ")
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _c("jodit-editor", {
        ref: "term-quill",
        attrs: {
          id: "term-quill",
          config: _vm.joditConfig,
          "read-only": "true"
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c(
        "app-dialog",
        {
          staticClass: "dialog-repeater-fields",
          attrs: {
            title:
              "Repeater " +
              ((_vm.repeater || {}).selected
                ? ((_vm.repeater || {}).selected || {}).Name
                : ""),
            fullscreen: true
          },
          on: {
            cancel: _vm.cancelDialogRepeater,
            confirm: _vm.confirmDialogRepeater
          },
          model: {
            value: _vm.repeater.dialog,
            callback: function($$v) {
              _vm.$set(_vm.repeater, "dialog", $$v)
            },
            expression: "repeater.dialog"
          }
        },
        [
          _c("template", { slot: "content" }, [
            _c(
              "div",
              [
                _vm.repeater.selected
                  ? _c("repeater-tags", {
                      attrs: {
                        "contractual-term": _vm.contractualTerm,
                        tag: _vm.repeater.selected
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }