var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "clause" },
      [
        _c("div", { staticClass: "vx-row p-3" }, [
          _c(
            "div",
            { staticClass: "w-full p-2" },
            [
              _c("vs-input", {
                staticClass: "w-full mb-10",
                attrs: {
                  label: "Nome da condição",
                  size: "large",
                  maxlength: "200"
                },
                model: {
                  value: _vm.objectData.Name,
                  callback: function($$v) {
                    _vm.$set(_vm.objectData, "Name", $$v)
                  },
                  expression: "objectData.Name"
                }
              }),
              _c(
                "vs-row",
                { staticClass: "mb-5" },
                [
                  _c("vs-switch", {
                    attrs: { disabled: _vm.objectData.IsFixedClause },
                    model: {
                      value: _vm.objectData.AllowBrokerSelection,
                      callback: function($$v) {
                        _vm.$set(_vm.objectData, "AllowBrokerSelection", $$v)
                      },
                      expression: "objectData.AllowBrokerSelection"
                    }
                  }),
                  _c("label", { staticClass: "ml-2" }, [
                    _vm._v(" Permitido Corretora Selecionar")
                  ])
                ],
                1
              ),
              _c(
                "vs-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "vs-tooltip",
                    {
                      attrs: {
                        position: "right",
                        text:
                          "Caso habilitado, a proposta cairá para mesa de subscrição caso a cláusula tenha sido selecionada"
                      }
                    },
                    [
                      _c("vs-switch", {
                        attrs: { disabled: _vm.objectData.IsFixedClause },
                        model: {
                          value: _vm.objectData.AllowAutomaticIssue,
                          callback: function($$v) {
                            _vm.$set(_vm.objectData, "AllowAutomaticIssue", $$v)
                          },
                          expression: "objectData.AllowAutomaticIssue"
                        }
                      })
                    ],
                    1
                  ),
                  _c("label", { staticClass: "ml-2" }, [
                    _vm._v("Emissão automática")
                  ])
                ],
                1
              ),
              _c(
                "vs-row",
                { staticClass: "mb-5" },
                [
                  _c("vs-switch", {
                    on: {
                      change: function($event) {
                        return _vm.setRequired()
                      }
                    },
                    model: {
                      value: _vm.objectData.IsFixedClause,
                      callback: function($$v) {
                        _vm.$set(_vm.objectData, "IsFixedClause", $$v)
                      },
                      expression: "objectData.IsFixedClause"
                    }
                  }),
                  _c("label", { staticClass: "ml-2" }, [
                    _vm._v(" Esta Cláusula deve ser fixa")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("contractual-term", {
          attrs: {
            allowedRepeater: true,
            allowedTextArea: true,
            jsonTagsAll: _vm.jsonTagsAll,
            valueTerm: _vm.objectData.ContractualTerm.Value,
            contractualTerm: _vm.objectData.ContractualTerm
          },
          on: {
            setJSONTagsAll: _vm.setJSONTagsAll,
            setValueTerm: _vm.setValueTerm
          }
        }),
        _c(
          "div",
          { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.loadingButton },
                on: {
                  click: function($event) {
                    return _vm.saveObject()
                  }
                }
              },
              [_vm._v("Salvar condição")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }